import React from "react";
import * as S from "./styles";

import { LayoutTemplate } from "../LayoutTemplate";
import { Button } from "../../Atoms/Button";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import CookieBannerPage from "../../Atoms/CookieBanner";

export const HomeTemplate = () => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });

  return (
    <LayoutTemplate>
      <CookieBannerPage />
      <S.Container>
        <S.main>
          <S.MainContent>
            <h1>
              Tokyo <span>vistoria</span>.
            </h1>
            <p>
              Nossa prioridade é oferecer serviços de vistorias veiculares com
              máxima <span>transparência</span> para todos os nossos clientes.
            </p>

            <p>
              Com profissionais experientes no mercado automotivo, nos
              destacamos na <span>excelência</span> dos nossos serviços com a
              qualidade que você merece.
            </p>

            <Link to={"agendamento"}>
              <Button data-variant-red-rectangle>AGENDE SUA VISTORIA</Button>
            </Link>
          </S.MainContent>
        </S.main>
        <S.About id="sobre">
          <S.AboutContent>
            <S.AboutLeft>
              <h2>
                O QUE VOCÊ ENCONTRA NA TOKYO<span>.</span>
              </h2>
            </S.AboutLeft>
            <S.AboutRight>
              <div>
                <img src="/assets/imgs/car1.svg" alt="icone de carro" />
                <p>
                  TODAS AS UNIDADES SÃO <span>CERTIFICADAS</span> PELO{" "}
                  <span>SISTEMA DE QUALIDADE</span> ISO 9001:2015.
                </p>
              </div>
              <div>
                <img src="/assets/imgs/car2.svg" alt="icone de carro" />
                <p>
                  <span>GARANTIA DE PROCEDÊNCIA</span> DO VEÍCULO A SER
                  ADQUIRIDO EVITANDO FRAUDES E ADULTERAÇÕES.
                </p>
              </div>
              <div>
                <img src="/assets/imgs/selo.svg" alt="icone de carro" />
                <p>
                  <span>GARANTIA DE TRANQUILIDADE</span> PARA QUEM VENDE.
                </p>
              </div>
            </S.AboutRight>
          </S.AboutContent>
          <Link to={"agendamento"}>
            <Button data-variant-red-rectangle>AGENDE SUA VISTORIA</Button>
          </Link>
        </S.About>
        <S.Services id="servicos">
          <S.ServicesContent>
            <S.ServiceLeftSide>
              <h2>
                nossos SERVIÇOS<span>.</span>
              </h2>

              <p>
                A <span>Vistoria de transferência</span> é essencial para
                garantir que o veículo esteja de acordo com as regulamentações
                locais e com todos os itens de segurança dentro das
                especificações do fabricante.
              </p>

              <p>
                Durante esse processo, nossos especialistas realizam uma
                inspeção detalhada, verificando diversos aspectos do veículo,
                dentre eles: <span>Identificação do Veículo</span>,{" "}
                <span>Condições Gerais</span>, <span>Segurança</span> e
                <span> Quilometragem</span>.
              </p>
            </S.ServiceLeftSide>

            <div>
              <Button onClick={() => window.open("/agendamento", "_self")}>
                AGENDE SUA VISTORIA
              </Button>
            </div>

            <S.ServiceRightSide>
              <S.CardPrice>
                <div className="text">
                  <h4>
                    Vistoria de transferência ou primeiro emplacamento Posto
                    fixo
                  </h4>
                </div>{" "}
                <div className="text">
                  <h4>MOTO / CARRO</h4>
                </div>
                <div className="black">
                  <h3>
                    R$ 152
                    {/* <img
                      src="/assets/imgs/two_asteriscs.svg"
                      alt="asteriscos"
                    />{" "} */}
                  </h3>
                </div>
              </S.CardPrice>

              <S.CardPrice>
                <div className="text">
                  <h4>
                    Vistoria de transferência / primeiro emplacamento domiciliar
                    - vEÍCULOS DE GRANDE PORTE
                    <span>*</span>
                  </h4>
                </div>{" "}
                <div className="black">
                  <h3>
                    {/* R$ 190
                    <img
                      src="/assets/imgs/two_asteriscs.svg"
                      alt="asteriscos"
                    />{" "} */}
                  </h3>
                </div>
              </S.CardPrice>
            </S.ServiceRightSide>

            <div>
              <S.BlackCard>
                <p>
                  <img
                    src="/assets/imgs/asteriscs.svg"
                    alt="icone de asterisco"
                  />
                  Micro-ônibus <span>.</span> Ônibus <span>.</span> Reboque{" "}
                  <span>.</span> Semirreboque <span>.</span> Caminhão{" "}
                  <span>.</span> Caminhão <span>.</span> Trator <span>.</span>{" "}
                  Trator de Rodas <span>.</span> Trator Misto <span>.</span>{" "}
                  Chassi/Plataforma
                </p>
              </S.BlackCard>
              {/* <S.BlackCard>
                <p>
                  <img
                    src="/assets/imgs/two_asteriscs.svg"
                    alt="icone de asterisco"
                  />
                  Taxa será acrescida no valor da compra. Taxa bancária no{" "}
                  <span>Boleto</span> no valor de <span>R$ 3,92</span>.
                </p>
              </S.BlackCard> */}
            </div>
          </S.ServicesContent>
        </S.Services>
        <S.Localizations id="localizacao">
          <S.LocalizationContent>
            {isMobile ? (
              <h2>
                localização<span>.</span>
              </h2>
            ) : (
              <h2>
                Onde estamos <span>localizados</span>.
              </h2>
            )}
            <S.WrapperLocalizations>
              <S.CardLocalization
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/2c3p2f4j2F5hWA4D9",
                    "_black"
                  )
                }
              >
                <img src="/assets/imgs/pin-map.svg" alt="pin mapa" />
                <span>
                  <p>sANTA INÊS / MA</p>
                </span>
              </S.CardLocalization>

              <S.CardLocalization
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/NDW1nZURLR8SHTE78",
                    "_black"
                  )
                }
              >
                <img src="/assets/imgs/pin-map.svg" alt="pin mapa" />
                <span>
                  <p>BARRA DO CORDA / MA</p>
                </span>
              </S.CardLocalization>

              <S.CardLocalization
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/iuMNkYpsXHzDEDcm6",
                    "_black"
                  )
                }
              >
                <img src="/assets/imgs/pin-map.svg" alt="pin mapa" />
                <span>
                  <p>CHAPADINHA / MA</p>
                </span>
              </S.CardLocalization>

              <S.CardLocalization
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/HgNngDwTyZNbNW6Q6",
                    "_black"
                  )
                }
              >
                <img src="/assets/imgs/pin-map.svg" alt="pin mapa" />
                <span>
                  <p>SÃO JOÃO DOS PATOS / MA</p>
                </span>
              </S.CardLocalization>

              <S.CardLocalization
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/qUTwwB3gJgJD91XP6",
                    "_black"
                  )
                }
              >
                <img src="/assets/imgs/pin-map.svg" alt="pin mapa" />
                <span>
                  <p>SÃO JOSÉ DE RIBAMAR/MA</p>
                </span>
              </S.CardLocalization>
            </S.WrapperLocalizations>
          </S.LocalizationContent>
        </S.Localizations>
        <S.Contact id="contato">
          <S.ContactContent>
            <h2>
              Contatos<span>.</span>
            </h2>

            <S.WrapperContacts>
              <S.ContactsLeftSide>
                <p>SANTA INÊS / MA</p>
                <p>BARRA DO CORDA / MA</p>
                <p>SÃO JOÃO DOS PATOS / MA</p>
              </S.ContactsLeftSide>

              <div>
                <h4>
                  <img
                    src="/assets/imgs/phone-dark.svg"
                    alt="icone de telefone"
                  />{" "}
                  (98) 98922-4823
                </h4>
              </div>

              <S.ContactsRightSide>
                <p>
                  SÃO JOSE DE <br />
                  RIBAMAR / MA
                </p>
                <p>CHAPADINHA / MA</p>
                {/* <p>SÃO JOÃO DOS PATOS / MA</p> */}
              </S.ContactsRightSide>

              <div>
                <h4>
                  <img
                    src="/assets/imgs/phone-dark.svg"
                    alt="icone de telefone"
                  />{" "}
                  (98) 99991-5907
                </h4>
              </div>
            </S.WrapperContacts>

            <S.WrapperContactsPhones></S.WrapperContactsPhones>
          </S.ContactContent>
        </S.Contact>
      </S.Container>
    </LayoutTemplate>
  );
};
